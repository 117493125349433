
import { Options, Vue } from 'vue-class-component';
import { AdminClient, BaseAuthClient } from '@/services/Services';
import { LoginServices } from '@/services/LoginServices';
import InfoModal from '@/components/modals/infoModal.vue';
import store from '@/store';
import * as OM from "@/Model";

@Options({})
export default class loginByDomain extends Vue {

    username: string = "";
    done: boolean = false;

    login() {
        BaseAuthClient.loginByDomain(this.username)
        .then(x => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("Check your email to complete login", "Check your email to complete login"),
                subtitle: "",
                deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
        .catch(y => {
            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("Error", "Error"),
                subtitle: y.Message,
                deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

    get disabled() {
        return !this.username.trim();
    }

}
